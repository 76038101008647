<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      width="500px"
      :mask-closable="false"
      title="审核"
      :ok-button-props="{ props: { loading: submitting } }"
      @ok="handleSubmit"
    >
      <template slot="footer">
        <a-button @click="closeModal">
          关闭
        </a-button>
      </template>
      <a-spin :spinning="loading">
        <a-form
          class="sfm-compact-form"
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-form-item label="审核人">
            {{ data.checker }}
          </a-form-item>

          <a-form-item label="审核时间">
            {{ formatTime(data.created_at) }}
          </a-form-item>

          <a-form-item label="审核结果">
              <a-radio-group
                v-decorator="['status',{
                  initialValue: 'passed'
                }]"
                disabled
              >
                <a-radio value="passed">
                  通过
                </a-radio>
                <a-radio value="not_pass">
                  未通过
                </a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="平台反馈">
              <a-textarea
                v-decorator="['reason', {
                  initialValue: data.reason,
                }]"
                disabled
                autocomplete="off"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { ReportRecordModel } from '@/models/report_record.js'
const reportRecordModel = new ReportRecordModel()
import formatTime from '@/utils/formatTime'

export default {
  name: 'ApprovalReportModal',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'approval_record' }),
      submitting: false,
      loading: true,
      formatTime
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    closeModal() {
      this.isShow = false
    },

    fetchData() {
      this.loading = true
      reportRecordModel.show(this.id).then(res => {
        if(res.code === 0) {
          this.data = res.result
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 5px;
}

.avatar-uploader > .ant-upload {
  width: 110px;
  height: 110px;
  float: left;
}

.icon {
  width: 86px;
}
</style>
